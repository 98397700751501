export default function Logo({logoURL, logoALT, animation}) {

    return (
        <img 
            id= "logo"
            className = "logo"
            src="images/logos/Jeddie_Logo_2023_1000px.png" 
            alt="Jeddie"
            onClick={animation}
        />
    );

}