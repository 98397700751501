import { useState } from 'react';
import '../css/Projects.css';

var projectList = [
    //[0 title, 1 languages, 2 buttonText, 3 demoURL, 4 githubURL, 5 month, 6 year, 7 details]
    ['photos.JoshEddie.com', ["HTML", "CSS", "JavaScript", "React"], "photos.JoshEddie.com", "https://photos.JoshEddie.com", "https://github.com/JoshEddie/photos_josheddie_com", "Sep", "2023", <p>Personal website for my photography hosted via Vercel using Git pushes to deploy new builds. Added React Router hooks to load components based on URL. Utilized states to filter and sort photos based on user actions.</p>],
    ["Database Project", ["HTML", "CSS", "JavaScript", "React", "Node.js", "PostgreSQL"], "", "", "https://github.com/JoshEddie/hw2_react", "Nov", "2023", <p>Full stack application for a mock cell phone company. Application allows filtering between accounts, transactions for payments, add lines, and view calls/data used. The database was designed with multiple tables at up to BCNF.</p>],
    ["User Action Tracking", ["Java"], "", "", "", "July", "2023", <p>Added feature to PROS Inc. enterprise software to track when users perform certain actions. This created a breadcrumb trail to be followed to make debugging problems easier as well as creating greater transparency with clients.<br/><br/>Addtionally, I wrote JUnit tests utilizing Mockito to ensure the code was fully functioning.</p>],
    ["Digital Image Processing", ["Python"], "", "", "", "Spring", "2023", <p>Created various small applications for a class on digital image processing at UH to perform needed processing on images. These ranged from denoise, shape detection, and undistorting images. </p>],
    ["eCommerce Front End Design", ["HTML, CSS, JavaScript"], "", "", "https://github.com/JoshEddie/proCart", "Oct", "2022", <p>Developed front end design for a 24 hour hackathon at UH with a team of 3 other students. The prompt was to develop an application that was solve a consumer need. We devised the idea for an all in one shopping cart that would allow you to shop multiple online retailers at one time and check out in a single transaction.</p>],
    ["RPG Game Battle Simulator", ["C++"], "", "", "https://github.com/JoshEddie/CLASH_RPGGameBattle", "April", "2022", <p>Developed C++ program where you create a character, choose one of three derived classes, and battle randomly simulated opponents. Progress is saved to a text file between sessions.</p>],
    ["Code Starter Template", [], "", "", "", "Feb", "2020", <p>Built JavaScript program for visually adding information and laying out an email. When finished the code would output a file with HTML and CSS that could be further developed. Was utilized to cut build time by 50% and minimize mistakes in code.</p>],
    ["Saint Studios Films", ["HTML, CSS, JavaScript"], "SaintStudiosFilms.com", "https://saintstudiosfilms.com/", "", "Oct", "2017", <p>Website created for indie film studio Saint Studio Films. Website was coded using HTML, CSS, and JavaScript.</p>]
]

function Project( {index, title, languages, details, buttonText, demoURL, GitHub, month, year, active, setActive} ) {

    function showProject(openClose) {

        var tempArray = Array(projectList.length).fill(false);
        
        if(openClose) {
            tempArray[index] = true;
        }

        setActive(tempArray);

    }

    return (
        <>
        <li onClick={() => showProject(true)} className={active[index] ? 'active' : ''}>
            {title} <span className='year'>({year})</span>
        </li>
        {active[index] ?
        <div className='project'>
            <span className='close' onClick={() => showProject(false)}>X</span>
            <h2>{title}</h2>
            <span className='languages'>{languages.join(', ')}</span>
            {details}
            {demoURL ? 
            <a href={demoURL} target='_blank'>{buttonText}</a> :
            ''}
            {GitHub ? 
            <a href={GitHub} target='_blank'>GitHub</a> :
            ''}
            <span className='date'>{month}, {year}</span>
        </div>
        :
        ''
        }
        </>
    )
}

export default function ProjectContainer() {

    var tempArray = Array(projectList.length).fill(false);

    if(window.innerWidth > 800) { 
        tempArray[0] = true;
    }
    const [showProject, setShowProject] = useState([...tempArray]);

    var projectComponents = []
    for(var i = 0; i < projectList.length; i++) {
        projectComponents.push(
            <Project 
                key={i}
                index={i}
                title={projectList[i][0]}
                languages={projectList[i][1]}
                details={projectList[i][7]}
                buttonText={projectList[i][2]}
                demoURL={projectList[i][3]}
                GitHub={projectList[i][4]} 
                month={projectList[i][5]} 
                year={projectList[i][6]} 
                active={showProject}
                setActive={setShowProject} 
            />
        )
      }

    return (
        <>
        <div id="projectcontainer">

            <h1>Projects</h1>
            <ul id="projectList">
                {projectComponents}
            </ul>
        </div>
        </>
    )
}