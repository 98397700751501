import './App.css';
import React, { useState, useEffect } from 'react';
import InfoContainer from './components/InfoContainer';
import ProjectContainer from './components/ProjectContainer';

export default function App() {

  return (
    <main id="container">
      <InfoContainer />
      <ProjectContainer />
    </main>
  )

}
