import '../css/Info.css';

import Logo from './Logo';
import Info from './Info';
import React, { useState, useEffect } from 'react';

export default function InfoContainer() {

    const [rotatedContainer, setRotatedContainer] = useState(null);
    const [transitionTime, setTransitionTime] = useState('5s')

    async function animation() {

        setTransitionTime('8s');

        await new Promise(resolve => setTimeout(resolve, 1));

        if(rotatedContainer === "rotated") {  
        setRotatedContainer(null);
        }
        else {
        setRotatedContainer('rotated');
        }

        await new Promise(resolve => setTimeout(resolve, 4000));

        setTransitionTime('1s')

    }

    useEffect(() => {
        animation();
    }, []);

    return (
        <div id="infoLogoContainer">
            <div id="infoLogo">
                <Logo
                    animation = {animation} 
                    />
                <Info />
            </div>
        </div>
    )

}